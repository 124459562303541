<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">FORM PERSETUJUAN TANPA NEGOSIASI (FPTN)</h4>
        </div>
        <div></div>
      </div>
      <div>
        <div class="flex justify-between mb-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col' : ''">
          <div class="pb-4">
            <div class="w-full mb-1">
              <label class="text-sm text-tertiary">Vendor #</label>
            </div>
            <h5 class="text-base text-gray-darkest">{{ negotiationFPTN.vendorName }}</h5>
          </div>
          <div>
            <button-gista
              @click="clickViewFPTN"
              type="secondary"
              color="success"
              size="small"
              v-if="menuRfqNegotiationFptnReport"
              :class="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
              >
              View FPTN
              <template #icon-left>
                <span class="mr-4 text-lg icon-eye text-success"></span>
              </template>
            </button-gista>
          </div>
        </div>
        <div v-if="!loading.loadingApprovedBanRfq && !negotiationFPTN?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
          <div class="border-gray-light">
            <div class="p-2">
              <p class="text-sm font-medium text-gray">Verified By</p>
            </div>
          </div>
          <table class="w-full" aria-describedby="verifiedBy">
            <thead>
              <tr class="bg-primary-lightest">
                <th class="p-2 border-t border-gray-light" :class="`${detailFPTNApprover?.representUkkpId && detailFPTNApprover?.representGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium text-left text-gray">DLOG</p>
                </th>
                <th class="p-2 border-t border-l border-gray-light" :class="`${detailFPTNApprover?.representUkkpId? 'w-1/3' : 'w-1/2'}`"  v-if="detailFPTNApprover?.representGsitId">
                  <p class="text-sm font-medium text-left text-gray">GSIT</p>
                </th>
                <th class="p-2 border-t border-l border-gray-light" :class="`${detailFPTNApprover?.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover?.representUkkpId">
                  <p class="text-sm font-medium text-left text-gray">UKKP</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="p-2 border-gray-light" :class="`${detailFPTNApprover.representUkkpId && detailFPTNApprover.representGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.itpVerificatorName}}</p>
                  <p v-if="detailFPTNApprover.itpVerificatorStatus" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailFPTNApprover.itpVerificatorStatus }} </p>
                  <p v-if="detailFPTNApprover.itpVerificatorApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.itpVerificatorApproved)">{{ detailFPTNApprover.itpVerificatorApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black whitespace-nowrap text-gray">&#183;</span><span>{{ detailFPTNApprover.itpVerificatorApproved?.split('|')[1] }}</span> 
                  </p>
                </td>
                <td class="p-2 border-l border-gray-light" :class="`${detailFPTNApprover.representUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.representGsitId">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.representGsitName}}</p>
                  <p v-if="detailFPTNApprover.representGsitStatus" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailFPTNApprover.representGsitStatus }}</p>
                  <p v-if="detailFPTNApprover.representGsitApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.representGsitApproved)">{{ detailFPTNApprover.representGsitApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailFPTNApprover.representGsitApproved?.split('|')[1] }}</span>
                  </p>
                </td>
                <td class="p-2 border-l border-gray-light" :class="`${detailFPTNApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.representUkkpId">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.representUkkpName}}</p>
                  <p v-if="detailFPTNApprover.representUkkpStatus" class="mt-1 text-sm font-medium text-warning-dark">{{ detailFPTNApprover.representUkkpStatus }}</p>
                  <p v-if="detailFPTNApprover.representUkkpApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.representUkkpApproved)">{{ detailFPTNApprover.representUkkpApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailFPTNApprover.representUkkpApproved?.split('|')[1] }}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
  
        </div>
        <div v-if="!loading.loadingApprovedBanRfq && !negotiationFPTN?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
          <div class="border-gray-light">
            <div class="p-2">
              <p class="text-sm font-medium text-gray">Approved By</p>
            </div>
          </div>
          <table class="w-full" aria-describedby="approvedBy">
            <thead>
              <tr class="bg-primary-lightest" :class="`${detailFPTNApprover.directApproverUkkpId && detailFPTNApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                <th class="p-2 border-t border-r border-gray-light" :class="`${detailFPTNApprover.directApproverUkkpId && detailFPTNApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium text-left text-gray">DLOG</p>
                </th>
                <th class="p-2 border-t border-gray-light" :class="`${detailFPTNApprover.directApproverUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.directApproverGsitId">
                  <p class="text-sm font-medium text-left text-gray">GSIT</p>
                </th>
                <th class="p-2 border-t border-l border-gray-light" :class="`${detailFPTNApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.directApproverUkkpId">
                  <p class="text-sm font-medium text-left text-gray">UKKP</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="p-2 border-r border-gray-light" :class="`${detailFPTNApprover.directApproverUkkpId && detailFPTNApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.directApproverItpName}}</p>
                  <p v-if="detailFPTNApprover.directApproverItpStatus" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailFPTNApprover.directApproverItpStatus }}</p>
                  <p v-if="detailFPTNApprover.directApproverItpApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.directApproverItpApproved)">{{ detailFPTNApprover.directApproverItpApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailFPTNApprover.directApproverItpApproved?.split('|')[1] }}</span>
                  </p>
                </td>
                <td class="p-2 border-gray-light" :class="`${detailFPTNApprover.directApproverUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.directApproverGsitId">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.directApproverGsitName}}</p>
                  <p v-if="detailFPTNApprover.directApproverGsitStatus" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailFPTNApprover.directApproverGsitStatus }}</p>
                  <p v-if="detailFPTNApprover.directApproverGsitApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.directApproverGsitApproved)">{{ detailFPTNApprover.directApproverGsitApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailFPTNApprover.directApproverGsitApproved?.split('|')[1] }}</span>
                  </p>
                </td>
                <td class="p-2 border-l border-gray-light"  :class="`${detailFPTNApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailFPTNApprover.directApproverUkkpId">
                  <p class="text-sm font-medium whitespace-nowrap text-gray">{{detailFPTNApprover.directApproverUkkpName}}</p>
                  <p v-if="detailFPTNApprover.directApproverUkkpStatus" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailFPTNApprover.directApproverUkkpStatus }}</p>
                  <p v-if="detailFPTNApprover.directApproverUkkpApproved" 
                    class="mt-1 text-sm font-medium text-gray-light">
                    <span :class="approvalColor(detailFPTNApprover.directApproverUkkpApproved)">{{ detailFPTNApprover.directApproverUkkpApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailFPTNApprover.directApproverUkkpApproved?.split('|')[1] }}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="loading.loadingApprovedBanRfq">
          <div class="animate-pulse">
            <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
            <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Detail FPTN</h5>
          </div>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-6 border-b border-gray-lightest pb-4"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <InputDisabled 
            label="Project ID" 
            :value="negotiationFPTN.projectId"
          />
          <InputDisabled 
            label="Request No." 
            :value="negotiationFPTN.requestNo"
          />
          <InputDisabled 
            label="Vendor Name" 
            :value="negotiationFPTN.vendorName"
          />
          <InputDisabled 
            label="Project Name" 
            :value="negotiationFPTN.projectName"
          />
          <div>
            <div class="mb-1">
              <p class="text-sm text-gray">Procurement Type</p>
            </div>
            <div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('REPEAT_ORDER')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Repeat Order</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('RENEWAL')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Renewal</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('CHANGE_REQUEST')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Change Request</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('ADDITIONAL')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Additional</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('ADVANCED_PROJECT')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Advanced Project</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.procurementType?.includes('DISCONTINUED_PRODUCT')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Discontinued Product</p>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-1">
              <p class="text-sm text-gray">Project Type</p>
            </div>
            <div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.projectType?.includes('HARDWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.projectType?.includes('SOFTWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Software</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.projectType?.includes('SERVICE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Service</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.projectType?.includes('MAINTENANCE')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="negotiationFPTN.projectType?.includes('LINK_COMMUNICATION')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
              </div>
            </div>
          </div>
          <InputDisabled 
            label="Offer No." 
            :value="negotiationFPTN.offerNo"
          />
          <InputDisabled 
            label="Offer Date" 
            :value="negotiationFPTN.offerDate"
          />
          <InputDisabled 
            label="Date Created" 
            :value="createdAt"
          />
          <InputDisabled 
            label="Reference Document" 
            :value="negotiationFPTN.referenceDoc"
          />
        </div>
        <div class="mb-6">
          <div class="flex flex-wrap justify-between gap-3 mb-6">
            <div class="flex items-center">
              <span class="mr-4 icon-list"></span>
              <h5 class="text-lg font-medium text-gray-darkest">Current Procurement Cost</h5>
            </div>
            <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                @click="clickToggleMaximizeTable"
                :disabled="disableMaximizeTable"
                >
              <span class="mr-3 text-lg icon-maximize text-gray"></span>
              <p class="text-sm font-semibold text-gray" >Maximize table</p>
            </button>
          </div>
          <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
          <NegotiationTableFptn />
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-6" 
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <div :class="quotationDetailContainerWidth < 768 ? '' : 'col-span-2'">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Payment Term
              </label>
            </div>
            <div class="">
              <TiptapView v-if="negotiationFPTN.paymentTerm" v-model="negotiationFPTN.paymentTerm" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <InputDisabled 
            label="VAT" 
            :value="vat"
            class="col-span-1"
          />
          <InputDisabled 
            label="Project Term" 
            :value="negotiationFPTN.jangkaWaktuProyek || '-'"
            class="col-span-1"
          />
          <div :class="quotationDetailContainerWidth < 768 ? '' : 'col-span-2'" v-for="n,i in fileQuotation" :key="i">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
              <div class="text-base text-gray">{{ n.fileName }}</div>
              <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
            </div>
          </div>
          <div :class="quotationDetailContainerWidth < 768 ? '' : 'col-span-2'" v-for="n,i in fileAttachment" :key="i+'a'">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
            </div>
            <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
              <div class="text-base text-gray">{{ n.fileName }}</div>
              <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
            </div>
          </div>
          <div :class="quotationDetailContainerWidth < 768 ? '' : 'col-span-2'">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <TiptapView v-if="negotiationFPTN.internalNote" v-model="negotiationFPTN.internalNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <InputDisabled 
            v-if="detailFPTNApprover.itpVerificatorName"
            label="ITP Verificator" 
            :value="detailFPTNApprover.itpVerificatorName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailFPTNApprover.directApproverItpName"
            label="Approval ITP" 
            :value="detailFPTNApprover.directApproverItpName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailFPTNApprover.representGsitName"
            label="Representative GSIT" 
            :value="detailFPTNApprover.representGsitName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailFPTNApprover.directApproverGsitName"
            label="Approval GSIT" 
            :value="detailFPTNApprover.directApproverGsitName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailFPTNApprover.representUkkpName"
            label="Representative UKKP" 
            :value="detailFPTNApprover.representUkkpName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailFPTNApprover.directApproverUkkpName"
            label="Approval UKKP" 
            :value="detailFPTNApprover.directApproverUkkpName?.toLowerCase()"
            class="capitalize"
          />
        </div>
      
      </div>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import NegotiationTableFptn from "@/core/components/rfq/negotiation/NegotiationTableFptn.vue";
import MaximizeTable from "@/core/components/rfq/negotiation/MaximizeTable.vue";
import TiptapView from '@/core/components/custom/TiptapView.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import BanLoading from '@/core/components/rfq/loading/BanLoading.vue'

export default {
  name: 'DetailFPTN',
  data() {
    return {
      isOpenMaximizeTable: false,
      time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      isLoading: false
    }
  },
  computed: {
    negotiationDetail(){
      return this.$store.state.negotiationRfq.negotiationDetail
    },
    disableMaximizeTable() {
      return this.negotiationDetail?.offers?.length === 0
    },
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    detailFPTNApprover() {
      return this.$store.state.negotiationRfq.detailRFQNegoApprover
    },
    negotiationFPTN() {
      return this.$store.state.negotiationRfq.negotiationFPTN
    },
    createdAt() {
      return this.negotiationFPTN.createdAt ? new Date(this.negotiationFPTN.createdAt).toLocaleDateString('id-ID') : ''
    },
    vat() {
      const taxType = this.negotiationFPTN?.tax?.taxType
      const ppn = this.negotiationFPTN?.tax?.ppn
      return ppn === 0 ? taxType : `${taxType} - ${ppn}%`
    },
    fileQuotation() {
      return this.negotiationFPTN?.attachments?.filter(i => i.fileRef === 'fileQuotation')
    },
    fileAttachment() {
      return this.negotiationFPTN?.attachments?.filter(i => i.fileRef === 'lampiranFptn')
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    loading() {
      return this.$store.state.modal.loading
    },
    menuRfqNegotiationFptnReport() {
      return this.$store.getters['dashboard/menuRfqNegotiationFptnReport']?.canRead
    },
    counterIntervalApproval() {
			return this.$store.state.negotiationRfq.counterInterval
		}
  },
  methods: {
    approvalColor(e) {
      return e?.split('|')[0].includes('Rejected') ? 'text-error' : 'text-success'
    },
    clickBackToVendorDetail() {
      this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', false)
      this.$router.go(-1)
    },
    async clickViewFPTN() {
      this.modal.modalLoading = true
      await this.$store.dispatch('negotiationRfq/getFptnReport', { negotiationId: this.$route.query.fptn })
      this.modal.modalLoading = false
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    clickToggleMaximizeTable() {
      this.isOpenMaximizeTable = !this.isOpenMaximizeTable
    },
  },
  async mounted() {
    this.isLoading = true
		const response = await this.$store.dispatch('negotiationRfq/getNegotiationList', { rfqId: this.$route.params.quotationId })
    const r1 = this.$store.dispatch('negotiationRfq/getDetailRFQNegoApprover', { negotiationId: this.$route.query.fptn })
    const r2 = this.$store.dispatch('negotiationRfq/getNegotiationFPTN',{ negotiationId: this.$route.query.fptn })
    const [res1, res2] = await Promise.all([r1, r2])
    if ([res1?.status, res2?.status, response?.status].every(e => e === 200)) {
      this.isLoading = false
    }
  },
  destroyed() {
    this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', false)
    clearInterval(this.counterIntervalApproval)
		this.$store.commit('negotiationRfq/SET_COUNTER_INTERVAL', null)
  },
  components: {
    MaximizeTable,
    TiptapView,
    InputDisabled,
    NegotiationTableFptn,
    BanLoading
  }
}
</script>

<style scoped>
.min-w-custom {
  min-width: 72rem;
}
.min-w-custom2 {
  min-width: 36rem;
}
</style>